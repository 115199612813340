import { Controller } from '@hotwired/stimulus';

export default class ToggleInlineCheckboxController extends Controller {
  static values = {
    formsetPrefix: String,
    formsetSuffix: String,
  };

  toggle() {
    const checkboxes = Array.from(
      document.querySelectorAll('input[type="checkbox"]'),
    ).filter((checkbox) => {
      const name = checkbox.getAttribute('name');
      return (
        name &&
        name.startsWith(this.formsetPrefixValue) &&
        name.endsWith(this.formsetSuffixValue)
      );
    });

    checkboxes.forEach((checkbox) => {
      checkbox.checked = !checkbox.checked;
    });
  }
}
