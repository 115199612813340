import { Controller } from '@hotwired/stimulus';

export default class ChangeListController extends Controller {
  static values = {
    rightAlignColumnNames: { type: Array, default: [] },
    centerAlignColumnNames: { type: Array, default: [] },
  };

  connect() {
    let rightAlignColumnNames = this.rightAlignColumnNamesValue;
    if (rightAlignColumnNames.length > 0) {
      let selector = rightAlignColumnNames
        .map(function (name) {
          return 'th.column-' + name + ', td.field-' + name;
        })
        .join(', ');
      $(selector).addClass('text-end');
    }
    let centerAlignColumnNames = this.centerAlignColumnNamesValue;
    if (centerAlignColumnNames.length > 0) {
      let selector = centerAlignColumnNames
        .map(function (name) {
          return 'th.column-' + name + ', td.field-' + name;
        })
        .join(', ');
      $(selector).addClass('text-center');
    }
  }
}
