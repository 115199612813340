import { Controller } from '@hotwired/stimulus';

export default class DatatableController extends Controller {
  static targets = ['table'];
  static values = {
    stateSave: { type: Boolean, default: false },
    search: { type: Boolean, default: false },
    paging: { type: Boolean, default: false },
    pageSize: { type: Number, default: 10 },
    showInfo: { type: Boolean, default: true },
    info: {
      type: String,
      default: 'Showing _START_ to _END_ of _TOTAL_ entries',
    },
    infoEmpty: { type: String, default: 'No entries to show' },
    lengthMenu: { type: String, default: 'Show _MENU_ entries' },
    scrollX: { type: Boolean, default: false },
  };

  connect() {
    this.table = new DataTable(this.tableTarget, {
      stateSave: this.stateSaveValue,
      searching: this.searchValue,
      paging: this.pagingValue,
      pageLength: this.pageSizeValue,
      info: this.showInfoValue,
      scrollX: this.scrollXValue,
      language: {
        paginate: {
          previous: "<i class='mdi mdi-chevron-left'>",
          next: "<i class='mdi mdi-chevron-right'>",
        },
        info: this.infoValue,
        infoEmpty: this.infoEmptyValue,
        lengthMenu: this.lengthMenuValue,
      },
      drawCallback: function () {
        $('.dataTables_paginate > .pagination').addClass('pagination-rounded');
      },
    });
  }
}
