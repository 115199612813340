import { Controller } from '@hotwired/stimulus';
import { useDispatch } from 'stimulus-use';

export default class ClipboardController extends Controller {
  connect() {
    useDispatch(this);
    $('p.form-control').after(
      "<div class='input-group-append'><button class='btn btn-outline-primary' data-action='clipboard#copy' type='button'><i class='mdi mdi-content-copy'></i></button></div>",
    );
  }

  copy(event) {
    event.preventDefault();
    let button = event.target.closest('button');
    let icon = $(button).find('i')[0];
    let content = $(event.target.closest('div.input-group')).find('p')[0]
      .innerHTML;
    const copyToClipboard = (str) => {
      if (navigator && navigator.clipboard && navigator.clipboard.writeText)
        return navigator.clipboard.writeText(str);
      return Promise.reject('The Clipboard API is not available.');
    };

    copyToClipboard(content).then(() => {
      icon.classList.add('mdi-check');
      icon.classList.remove('mdi-content-copy');
      button.classList.add('btn-outline-success');
      setTimeout(() => {
        icon.classList.remove('mdi-check');
        icon.classList.add('mdi-content-copy');
        button.classList.remove('btn-outline-success');
      }, 2500);
    });
  }
}
