import { Controller } from '@hotwired/stimulus';

export default class CheckboxListController extends Controller {
  static targets = ['checkAll'];

  // Actions
  checkAll() {
    this.setAllCheckboxes(true);
  }

  checkNone() {
    this.setAllCheckboxes(false);
  }

  toggleAll(event) {
    this.setAllCheckboxes(event.target.checked);
  }

  // Private
  setAllCheckboxes(checked) {
    this.checkboxes.forEach((el) => {
      const checkbox = el;

      if (!checkbox.disabled) {
        checkbox.checked = checked;
      }
    });
  }

  get checkboxes() {
    return this.element.querySelectorAll('input[type=checkbox]');
  }
}
