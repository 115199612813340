import { Controller } from '@hotwired/stimulus';

export default class ScreeningRelationInlineController extends Controller {
  connect() {
    // Assumes the inline group has a specific Stimulus controller target name
    var firstInline = this.element.querySelector('.form-row:not(.empty-form)');
    if (firstInline) {
      let inputs = firstInline.querySelectorAll('input');
      inputs.forEach(function (input) {
        input.setAttribute('readonly', true); // Make input readonly
      });
      let deleteCheckbox = firstInline.querySelector(
        'input[type="checkbox"][name$="DELETE"]',
      );
      if (deleteCheckbox) {
        deleteCheckbox.disabled = true; // Disable the delete checkbox
      }
      let select = firstInline.querySelector('select[name$="type"]');
      select.addEventListener('change', function (event) {
        // Revert the selection if changed
        event.preventDefault();
        this.selectedIndex = this.dataset.selectedIndex;
      });

      // Store the initial selected index
      select.dataset.selectedIndex = select.selectedIndex;
    }
  }
}
