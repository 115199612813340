/*
See https://docs.dropzone.dev/configuration/basics/configuration-options
 */
import { Controller } from '@hotwired/stimulus';
import { useDispatch } from 'stimulus-use';

export default class DropzoneController extends Controller {
  static targets = [
    'dropzone',
    'previewsContainer',
    'uploadPreviewTemplate',
    'progress',
    'progressBar',
  ];
  static values = {
    url: { type: String, default: '' },
    files: { type: Array, default: [] },
    maxFilesize: { type: Number, default: 10 },
    uploadMultiple: { type: Boolean, default: true },
    paramName: { type: String, default: 'file' },
    createImageThumbnails: { type: Boolean, default: true },
    autoProcessQueue: { type: Boolean, default: false },
    maxFiles: { type: Number, default: 100 },
    parallelUploads: { type: Number, default: 100 },
    acceptedFiles: { type: String, default: '' },
  };

  connect() {
    useDispatch(this);
    const options = {
      url: this.urlValue,
      uploadMultiple: this.uploadMultipleValue,
      maxFilesize: this.maxFilesizeValue,
      paramName: this.paramNameValue,
      createImageThumbnails: this.createImageThumbnailsValue,
      autoProcessQueue: this.autoProcessQueueValue,
      parallelUploads: this.parallelUploadsValue,
    };
    if (this.maxFilesValue) {
      options['maxFiles'] = this.maxFilesValue;
    }
    if (this.previewsContainerTarget) {
      options['previewsContainer'] = this.previewsContainerTarget;
    }
    if (this.uploadPreviewTemplateTarget) {
      options['previewTemplate'] = $(this.uploadPreviewTemplateTarget).html();
    }
    if (this.acceptedFilesValue) {
      options['acceptedFiles'] = this.acceptedFilesValue;
    }
    this.dropzone = new Dropzone(this.dropzoneTarget, options);
  }

  addedFiles(event) {
    const element = document
      .querySelectorAll('.dz-error:not(.animate__animated)')
      .forEach((el) => {
        el.classList.add('animate__animated', 'animate__shakeX');
      });
    this.removeDuplicateFiles(this.dropzone.files);
  }

  removeDuplicateFiles(files) {
    let i,
      len = files.length,
      out = [],
      obj = {};

    for (i = 0; i < len; i++) {
      let key = `${files[i].name}_${files[i].size}`;
      if (key in obj) {
        this.dropzone.removeFile(files[i]);
      } else {
        obj[`${key}`] = files[i];
      }
    }
  }

  updateProgress(event) {
    let [progress, totalBytes, totalBytesSent] = event.detail.args;
    if (totalBytes != 0) {
      this.progressBarTarget.setAttribute('aria-valuenow', progress);
      this.progressBarTarget.style.width = `${progress}%`;
    }
  }

  processCompleted(event) {
    let e = event.detail.args[2];
    if (e.currentTarget.status == 200) {
      window.location.replace(e.currentTarget.responseURL);
    }
  }

  submit(event) {
    event.preventDefault();
    event.stopPropagation();
    this.dropzone.processQueue();
  }
}
