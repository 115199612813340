import { Controller } from '@hotwired/stimulus';

export default class TooltipController extends Controller {
  static targets = ['tooltipButton'];
  static values = {
    title: String,
    placement: { type: String, default: 'top' },
  };

  connect() {
    // Initialize Bootstrap tooltip on the element
    this.tooltip = new bootstrap.Tooltip(this.tooltipButtonTarget, {
      title: this.titleValue,
      placement: this.placementValue,
      trigger: 'hover',
    });
  }

  disconnect() {
    // Dispose of the tooltip when the element is disconnected
    this.tooltip.dispose();
  }
}
