import { Controller } from '@hotwired/stimulus';

export default class ApexChartsAreaController extends Controller {
  static targets = ['chart'];
  static values = {
    seriesData: { type: Array, default: [] },
    seriesNames: { type: Array, default: [] },
    colors: Array,
    chartHeight: { type: Number, default: 250 },
    chartToolbarShow: { type: Boolean, default: true },
    dataLabelsEnabled: { type: Boolean, default: false },
    legendShow: { type: Boolean, default: true },
    legendShowForSingleSeries: { type: Boolean, default: false },
    gridBorderColor: { type: String, default: '#f1f3fa' },
    yaxisOpposite: { type: Boolean, default: true },
    strokeWidth: { type: Number, default: 2 },
  };

  connect() {
    // construct series data
    let seriesData = [];
    for (let i = 0, len = this.seriesDataValue.length; i < len; i++) {
      if (i < this.seriesNamesValue.length) {
        seriesData.push({
          name: this.seriesNamesValue[i],
          data: this.seriesDataValue[i],
        });
      } else {
        seriesData.push({
          data: this.seriesDataValue[i],
        });
      }
    }

    // construct chart options
    const options = {
      chart: {
        type: 'area',
        height: this.chartHeightValue,
        zoom: {
          type: 'x',
          enabled: true,
          autoScaleYaxis: true,
        },
        toolbar: {
          show: this.chartToolbarShowValue,
        },
      },
      dataLabels: {
        enabled: this.dataLabelsEnabledValue,
      },
      series: seriesData,
      colors: this.colorsValue,
      stroke: {
        width: this.strokeWidthValue,
        curve: 'smooth',
      },
      legend: {
        show: this.legendShowValue,
        showForSingleSeries: this.legendShowForSingleSeriesValue,
      },
      xaxis: {
        type: 'datetime',
      },
      yaxis: {
        opposite: this.yaxisOppositeValue,
        labels: {
          maxWidth: 300,
        },
        forceNiceScale: true,
        min: undefined,
        max: undefined,
      },
      tooltip: {
        x: {
          format: 'dd MMM yyyy',
        },
        y: {
          formatter: function (val) {
            return val.toFixed(0);
          },
        },
      },
      markers: {
        size: 0,
        style: 'hollow',
      },
      fill: {
        type: 'gradient',
        gradient: {
          shadeIntensity: 1,
          opacityFrom: 0.7,
          opacityTo: 0.9,
          stops: [0, 100],
        },
      },
      grid: {
        borderColor: this.gridBorderColorValue,
      },
    };

    this.chart = new ApexCharts(this.chartTarget, options);
    this.chart.render();
  }

  lastMonth(event) {
    const today = new Date();
    this.chart.updateOptions({
      xaxis: {
        min: new Date().setMonth(today.getMonth() - 1),
        max: today.getTime(),
      },
    });
  }

  lastThreeMonth(event) {
    const today = new Date();
    this.chart.updateOptions({
      xaxis: {
        min: new Date().setMonth(today.getMonth() - 3),
        max: today.getTime(),
      },
    });
  }

  lastSixMonth(event) {
    const today = new Date();
    this.chart.updateOptions({
      xaxis: {
        min: new Date().setMonth(today.getMonth() - 6),
        max: today.getTime(),
      },
    });
  }

  lastYear(event) {
    const today = new Date();
    this.chart.updateOptions({
      xaxis: {
        min: new Date().setFullYear(today.getFullYear() - 1),
        max: today.getTime(),
      },
    });
  }

  yearToDate(event) {
    const today = new Date();
    const yearStart = new Date(today.getFullYear(), 0, 1);
    this.chart.updateOptions({
      xaxis: {
        min: yearStart.getTime(),
        max: today.getTime(),
      },
    });
  }

  all(event) {
    this.chart.updateOptions({
      xaxis: {
        min: undefined,
        max: undefined,
      },
    });
  }
}
