import { Controller } from '@hotwired/stimulus';

export default class ApexChartsBarController extends Controller {
  static targets = ['chart'];
  static values = {
    seriesData: { type: Array, default: [] },
    seriesNames: { type: Array, default: [] },
    colors: Array,
    chartHeight: { type: Number, default: 250 },
    chartStacked: { type: Boolean, default: false },
    chartToolbarShow: { type: Boolean, default: true },
    dataLabelsEnabled: { type: Boolean, default: true },
    legendShow: { type: Boolean, default: true },
    legendShowForSingleSeries: { type: Boolean, default: false },
    gridBorderColor: { type: String, default: '#f1f3fa' },
    xaxisCategories: Array,
  };

  connect() {
    // construct series data
    let seriesData = [];
    for (let i = 0, len = this.seriesDataValue.length; i < len; i++) {
      if (i < this.seriesNamesValue.length) {
        seriesData.push({
          name: this.seriesNamesValue[i],
          data: this.seriesDataValue[i],
        });
      } else {
        seriesData.push({
          data: this.seriesDataValue[i],
        });
      }
    }

    // construct chart options
    const options = {
      chart: {
        type: 'bar',
        height: this.chartHeightValue,
        stacked: this.chartStackedValue,
        toolbar: {
          show: this.chartToolbarShowValue,
        },
      },
      plotOptions: {
        bar: {
          horizontal: true,
        },
      },
      dataLabels: {
        enabled: this.dataLabelsEnabledValue,
      },
      series: seriesData,
      colors: this.colorsValue,
      stroke: {
        width: 1,
        colors: ['#fff'],
      },
      legend: {
        show: this.legendShowValue,
        showForSingleSeries: this.legendShowForSingleSeriesValue,
      },
      xaxis: {
        categories: this.xaxisCategoriesValue,
        decimalsInFloat: 0,
        forceNiceScale: true,
      },
      yaxis: {
        labels: {
          maxWidth: 300,
        },
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val.toFixed(0);
          },
          title: {
            formatter: function (seriesName) {
              return '';
            },
          },
        },
      },
      states: {
        hover: {
          filter: 'none',
        },
      },
      grid: {
        borderColor: this.gridBorderColorValue,
      },
    };

    const chart = new ApexCharts(this.chartTarget, options);
    chart.render();
  }
}
