import { Controller } from '@hotwired/stimulus';
import { useDispatch } from 'stimulus-use';

export default class SpinnerButtonController extends Controller {
  static targets = ['button'];
  static values = {
    loadingText: String,
    spinnerBorderClass: {
      type: String,
      default: 'spinner-border spinner-border-sm me-1',
    },
    disableWhenLoading: { type: Boolean, default: true },
  };

  connect() {
    useDispatch(this);
    this.originalText = this.buttonTarget.textContent;
    this.isLoading = false;
  }

  toggleLoading() {
    if (this.isLoading) {
      this.buttonTarget.innerHTML = `${this.originalText}`;
      if (this.disableWhenLoadingValue) {
        this.buttonTarget.removeAttribute('disabled');
      }
    } else {
      this.buttonTarget.innerHTML = `<span class="${this.spinnerBorderClassValue}"></span> ${this.originalText}`;
      if (this.disableWhenLoadingValue) {
        this.buttonTarget.setAttribute('disabled', '');
      }
    }
    // toggle isLoading
    this.isLoading = !this.isLoading;
  }
}
