import { Controller } from '@hotwired/stimulus';
import { useDispatch } from 'stimulus-use';

export default class HoldingsValueChartController extends Controller {
  static targets = ['chart'];
  static values = {
    seriesData: { type: Array, default: [] },
    seriesNames: { type: Array, default: [] },
    transactionsData: { type: Array, default: [] },
    currencySymbol: { type: String, default: '€' },
    colors: Array,
    chartHeight: { type: String, default: '300px' },
    chartGroup: String,
    chartToolbarShow: { type: Boolean, default: true },
    dataLabelsEnabled: { type: Boolean, default: false },
    legendShow: { type: Boolean, default: true },
    legendShowForSingleSeries: { type: Boolean, default: false },
    gridBorderColor: { type: String, default: '#f1f3fa' },
    yaxisOpposite: { type: Boolean, default: true },
    yaxisLabelsMinWith: { type: Number, default: 40 },
    strokeWidth: { type: Number, default: 2 },
  };

  connect() {
    useDispatch(this);
    let currencySymbol = this.currencySymbolValue;
    // construct series data
    let seriesData = [];
    for (let i = 0, len = this.seriesDataValue.length; i < len; i++) {
      if (i < this.seriesNamesValue.length) {
        seriesData.push({
          name: this.seriesNamesValue[i],
          data: this.seriesDataValue[i],
        });
      } else {
        seriesData.push({
          data: this.seriesDataValue[i],
        });
      }
    }
    let annotations = [];
    for (let i = 0, len = this.transactionsDataValue.length; i < len; i++) {
      let value = this.transactionsDataValue[i][1];
      let color = '#00E396';
      if (value < 0) {
        color = '#FF5B5B';
      }
      annotations.push({
        x: new Date(this.transactionsDataValue[i][0]).getTime(),
        strokeDashArray: 0,
        borderColor: color,
        label: {
          borderColor: color,
          style: {
            color: '#FFF',
            background: color,
          },
          text: `${Math.abs(value).toLocaleString(
            document.documentElement.lang,
            {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            },
          )} ${currencySymbol}`,
        },
      });
    }

    // construct chart options
    const options = {
      chart: {
        group: this.chartGroupValue,
        type: 'area',
        height: this.chartHeightValue,
        toolbar: {
          show: this.chartToolbarShowValue,
        },
        zoom: {
          autoScaleYaxis: true,
        },
      },
      dataLabels: {
        enabled: this.dataLabelsEnabledValue,
      },
      series: seriesData,
      colors: this.colorsValue,
      stroke: {
        width: this.strokeWidthValue,
        curve: 'smooth',
      },
      legend: {
        show: this.legendShowValue,
        showForSingleSeries: this.legendShowForSingleSeriesValue,
      },
      xaxis: {
        type: 'datetime',
      },
      yaxis: {
        opposite: this.yaxisOppositeValue,
        labels: {
          maxWidth: 300,
          formatter: function (val) {
            let v = val.toLocaleString(document.documentElement.lang, {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            });
            return `${currencySymbol} ${v}`;
          },
        },
      },
      tooltip: {
        x: {
          format: 'dd MMM yyyy',
          show: false,
        },
        y: {
          formatter: function (val) {
            let v = val.toLocaleString(document.documentElement.lang, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            });
            return `${currencySymbol} ${v}`;
          },
        },
      },
      annotations: {
        xaxis: annotations,
      },
      markers: {
        size: 0,
        style: 'hollow',
      },
      fill: {
        type: 'gradient',
        gradient: {
          shadeIntensity: 1,
          opacityFrom: 0.7,
          opacityTo: 0.9,
          stops: [0, 100],
        },
      },
      grid: {
        borderColor: this.gridBorderColorValue,
      },
    };

    if (this.chartTarget.id) {
      options.chart.id = this.chartTarget.id;
    }

    this.chart = new ApexCharts(this.chartTarget, options);
    this.chart.render();
  }

  toggleNavLink(event) {
    document.querySelectorAll('.toolbar .nav-link').forEach((el) => {
      el.classList.add('text-muted');
    });
    event.target.classList.remove('text-muted');
  }

  lastMonth(event) {
    this.toggleNavLink(event);
    const today = new Date();
    this.chart.updateOptions({
      xaxis: {
        min: new Date().setMonth(today.getMonth() - 1),
        max: today.getTime(),
      },
    });
  }

  lastThreeMonth(event) {
    this.toggleNavLink(event);
    const today = new Date();
    this.chart.updateOptions({
      xaxis: {
        min: new Date().setMonth(today.getMonth() - 3),
        max: today.getTime(),
      },
    });
  }

  lastSixMonth(event) {
    this.toggleNavLink(event);
    const today = new Date();
    this.chart.updateOptions({
      xaxis: {
        min: new Date().setMonth(today.getMonth() - 6),
        max: today.getTime(),
      },
    });
  }

  lastYear(event) {
    this.toggleNavLink(event);
    const today = new Date();
    this.chart.updateOptions({
      xaxis: {
        min: new Date().setFullYear(today.getFullYear() - 1),
        max: today.getTime(),
      },
    });
  }

  yearToDate(event) {
    this.toggleNavLink(event);
    const today = new Date();
    const yearStart = new Date(today.getFullYear(), 0, 1);
    this.chart.updateOptions({
      xaxis: {
        min: yearStart.getTime(),
        max: today.getTime(),
      },
    });
  }

  all(event) {
    this.toggleNavLink(event);
    this.chart.updateOptions({
      xaxis: {
        min: undefined,
        max: undefined,
      },
    });
  }
}
