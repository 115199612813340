import { Controller } from '@hotwired/stimulus';

export default class UnsavedFormController extends Controller {
  static values = {
    message: {
      type: String,
      default: 'You have unsaved changes. Do you want to leave?',
    },
  };

  connect() {
    this.formChanged = false;
    this.isSubmitting = false; // Flag to check if form is being submitted
    window.addEventListener('beforeunload', this.promptOnNav.bind(this));

    // Add action to all fields using jQuery
    $(this.element)
      .find('input, select, textarea')
      .on('input', this.monitorChanges.bind(this));

    // Set the isSubmitting flag when the form is submitted
    $(this.element).on('submit', () => {
      this.isSubmitting = true;
    });
  }

  disconnect() {
    window.removeEventListener('beforeunload', this.promptOnNav.bind(this));
    // Unbind the actions using jQuery
    $(this.element)
      .find('input, select, textarea')
      .off('input', this.monitorChanges.bind(this));
    $(this.element).off('submit');
  }

  monitorChanges() {
    this.formChanged = true;
  }

  promptOnNav(event) {
    if (this.formChanged && !this.isSubmitting) {
      // Check the isSubmitting flag here
      event.preventDefault();
      event.returnValue = this.messageValue;
    }
  }
}
