import { Controller } from '@hotwired/stimulus';

export default class ApexChartsDonutController extends Controller {
  static targets = ['chart'];
  static values = {
    seriesData: { type: Array, default: [] },
    labels: { type: Array, default: [] },
    colors: {
      type: Array,
      default: [
        '#5e4fa2',
        '#3288bd',
        '#66c2a5',
        '#abdda4',
        '#e6f598',
        '#fee08b',
        '#fdae61',
        '#f46d43',
        '#d53e4f',
        '#9e0142',
      ],
    },
    labelsShow: { type: Boolean, default: true },
    chartWidth: { type: String, default: '100%' },
    chartHeight: { type: String, default: '100%' },
    chartToolbarShow: { type: Boolean, default: true },
    dataLabelsEnabled: { type: Boolean, default: true },
    legendShow: { type: Boolean, default: true },
    legendShowForSingleSeries: { type: Boolean, default: false },
  };

  connect() {
    // construct chart options
    const options = {
      chart: {
        type: 'donut',
        width: this.chartWidthValue,
      },
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: this.labelsShowValue,
              name: {
                color: '#000',
              },
              value: {
                formatter: function (value) {
                  return value + '%';
                },
              },
            },
          },
        },
      },
      series: this.seriesDataValue,
      labels: this.labelsValue,
      colors: this.colorsValue,
      dataLabels: {
        enabled: this.dataLabelsEnabledValue,
        formatter: function (value) {
          return value.toFixed(2);
        },
        dropShadow: {
          blur: 3,
          opacity: 0.8,
        },
      },
      tooltip: {
        enabled: false,
        y: {
          formatter: function (value) {
            return value.toFixed(2);
          },
        },
        fillSeriesColor: false,
        theme: 'light',
      },
      states: {
        hover: {
          filter: 'none',
        },
      },
      legend: {
        show: this.legendShowValue,
        showForSingleSeries: this.legendShowForSingleSeriesValue,
        position: 'bottom',
      },
    };

    this.chart = new ApexCharts(this.chartTarget, options);
    this.chart.render();
  }
}
