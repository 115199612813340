import { Controller } from '@hotwired/stimulus';

export default class BulkController extends Controller {
  static targets = ['checkbox', 'selectedIds'];

  update(event) {
    const selected = this.checkboxTargets.filter((el) => el.checked);
    this.selectedIdsTargets.forEach(
      (field) => (field.value = selected.map((x) => x.dataset.id).join()),
    );
  }
}
